<template>
  <div class="expert_management">
    <el-form class="zwx-form" key="expertManagementForm" ref="expertManagementForm" :model="expertManagementForm" label-position="right" @submit.native.prevent>
      <div>
        <el-form-item label-width="42px" label="姓名：">
          <el-input class="zwx-input" v-model.trim="expertManagementForm.expertName" style="width: 200px !important" placeholder="" clearable />
        </el-form-item>
        <el-form-item label-width="70px" label="单位名称：">
          <el-input class="zwx-input" v-model.trim="expertManagementForm.unitName" style="width: 200px !important" placeholder="" clearable />
        </el-form-item>
      </div>
    </el-form>
    <el-divider class="base-divider" />
    <div style="padding: 12px 16px;">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryExpertList(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">
        添加
      </el-button>
    </div>

    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="expertManagementForm.tableList" border stripe tooltip-effect="light">
      <el-table-column prop="orderId" label="排序号" width="80" header-align="center" align="center" />
      <el-table-column prop="expertName" label="姓名" min-width="100" header-align="center" align="center" />
      <el-table-column prop="unitName" label="单位名称" width="260" header-align="center" align="left" />
      <el-table-column prop="professionalTittle" label="职称" min-width="100" header-align="center" align="center" />
      <el-table-column prop="expertIntroduction" label="简介" min-width="380" header-align="center" align="left" show-overflow-tooltip />
      <el-table-column fixed="right" label="操作" min-width="110" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="edit(scope.row)">
            编辑
          </el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteRow(scope.row.uuid)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 分页器部分 -->
    <base-pagination class="normal-pagination" :parentPage="expertManagementForm.currentPage" :pageSize="expertManagementForm.pageSize" :total="expertManagementForm.total" @currentChange="queryExpertList" />
  </div>
</template>

<script>
export default {
  name: 'ExpertManagement',
  data() {
    return {
      api: this.$store.state.api,
      expertManagementForm: {
        expertName: '',
        unitName: '',
        tableList: [],
        currentPage: 1,
        pageSize: 4,
        total: 0,
      },
    }
  },
  mounted() {
    this.queryExpertList(1)
  },
  activated() {
    if (this.$route.params.fresh) this.queryExpertList(1)
  },
  methods: {
    queryExpertList(currentPage) {
      this.expertManagementForm.currentPage = currentPage
      let data = {
        ...this.expertManagementForm,
      }
      this.$system.postJson(
        this.api + '/training/platform/getTrainingExpertList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.expertManagementForm.tableList = data.trainingExpertList
            this.expertManagementForm.pageSize = data.pageSize
            this.expertManagementForm.total = data.trainingExpertCount
          } else if (data.type === '99') {
            this.$router.push({ name: 'MsError', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.error({ title: '错误', message: data.mess })
        }
      )
    },
    add() {
      this.$router.push({ name: 'ExpertManagementEdit', params: { type: 'add' } })
    },
    edit(row) {
      this.$router.push({ name: 'ExpertManagementEdit', params: { row: row, type: 'edit' } })
    },
    deleteRow(uuid) {
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        let data = {
          uuid,
        }
        this.$system.post(
          this.api + '/training/platform/deleteTrainingExpert-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryExpertList(1)
            } else if (data.type === '99') {
              this.$router.push({ name: 'MsError', params: { mess: data.mess } })
            } else {
              this.$notify.error({ title: '错误', message: data.mess })
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.error({ title: '错误', message: data.mess })
          }
        )
      })
    },
  },
}
</script>

<style lang="less" scoped>
.expert_management {
}
</style>
<style lang="less"></style>
