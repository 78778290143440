<template>
  <div class="department-station">
    <div class="station-list">
      <div class="station-search">
        <el-input class="zwx-input" style="width: 270px !important;margin-right: 10px !important;" placeholder="请输入" v-model.trim="workplaceNameLike" @keyup.enter.native="workplaceNameSelect(workplaceNameLike)">
          <el-link :underline="false" style="margin-right: 5px !important;margin-top:10px" slot="suffix" type="primary" icon="el-icon-search" @click="workplaceNameSelect(workplaceNameLike)"></el-link>
        </el-input>
        <el-button class="zwx-button zwx-button-icontext-28" style="margin-right:12px" icon="el-icon-plus" @click="addDepart">添加</el-button>
        <el-dropdown @command="handleCommand">
          <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-download">
            导入
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="2">
              岗位（工种）
            </el-dropdown-item>
            <el-dropdown-item command="1">
              部门（车间）
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <el-tree class="filter-tree" :data="data" :props="defaultProps" default-expand-all ref="tree" :expand-on-click-node="false" @node-click="departClick" highlight-current>
        <div class="custom-tree-node" slot-scope="{ node, data }" style="display:flex;justify-content: space-between; width:100%" @mouseenter="mouseenter(data)" @mouseleave="mouseleave(data)">
          <div style="display:flex;">
            <div :class="$zwxBase.verifyIsBlank(node.rid) ? '' : 'name-hidden'">{{ node.label }}</div>
            <div style="display:flex;align-items:center">
              <i class="depart-people filled chis-icon-filled-people" v-if="$zwxBase.verifyIsNotBlank(data.principal) || $zwxBase.verifyIsNotBlank(data.mobilePhoneNumber)"></i>
              <div class="principal-hidden" style="width:60px">{{ data.principal }}</div>
              <div>{{ data.mobilePhoneNumber }}</div>
              <div v-if="$zwxBase.verifyIsBlank(data.mobilePhoneNumber)">{{ data.fixedTelephone }}</div>
            </div>
          </div>
          <div class="table-item-add-icon" v-show="data.show">
            <i class="depart-add outline chis-icon-outline-add" @click.stop="addDepart(data)"></i>
            <i class="depart-edit el-icon-edit" @click.stop="editDepartRow(data)"></i>
            <i class="depart-delete el-icon-delete" @click.stop="deleteDepart(data)"></i>
          </div>
        </div>
      </el-tree>
    </div>
    <div class="equipment-detail">
      <div class="header-title">
        <span>{{ workplaceName }}</span>
        <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addJob" v-show="addShow">添加岗位（工种）</el-button>
      </div>
      <el-table ref="multipleTable" class="zwx-table" style="width: 100%;" row-key="rid" :data="jobInfoList" border stripe tooltip-effect="light" height="calc(100vh - 78px)">
        <el-table-column prop="jobName" label="岗位（工种）名称" width="160" header-align="center" align="left"></el-table-column>
        <el-table-column label="是否接害" width="100" header-align="center" align="center">
          <template slot-scope="scope">
            {{ scope.row.exposureHazards ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="involvedHazards" label="危害因素" width="300" header-align="center" align="left" show-overflow-tooltip :formatter="involvedHazardsFormatter"></el-table-column>
        <el-table-column prop="equippedPpe" label="配备防护用品" width="500" header-align="center" align="left" show-overflow-tooltip :formatter="equippedPpeFormatter"></el-table-column>
        <el-table-column fixed="right" label="操作" min-width="130" header-align="left" align="left">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="editJob(scope.row, 1)">
              编辑
              <i class="el-icon-top-right"></i>
            </el-button>
            <el-button class="zwx-button zwx-button-text-26 zwx-button-danger" type="danger" @click="$system.msgbox(scope.row, '提示', '是否确认删除？', '确定', '取消', deleteJob)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加部门弹框 -->
    <base-dialog class="depart-edit-dialog" ref="addDepartDialog" :title="departDialogTitle" :pagination="false" :if-show-close="true" @cancel="handleCloseDep()" @close="closeDep" @determine="submitDepAdd">
      <el-form class="zwx-form edit-form" @submit.native.prevent key="msFormDepAdd" ref="msFormDepAdd" :rules="rulesDepAdd" :model="msFormDepAdd" label-position="right">
        <div class="edit-row">
          <el-form-item label-width="100px" label="上级部门：" prop="parentWorkplaceId">
            <cascader-options-single class="zwx-cascader-single-depart" ref="parentDeptCascaderRef" :width="200" :options="departCascaderData" v-model="msFormDepAdd.parentWorkplaceId" expandTrigger="hover" :checkStrictly="true" dataNameFiled="workplaceName" dataCodeFiled="rid" dataUpCodeFiled="departmentInfoChildList"></cascader-options-single>
          </el-form-item>
          <el-form-item label-width="110px" label="部门（车间）：" prop="workplaceName">
            <el-input class="zwx-input" style="width: 200px !important;" placeholder="请输入" v-model.trim="msFormDepAdd.workplaceName" maxlength="50" clearable></el-input>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="100px" label="编号：" prop="workplaceCode">
            <el-input class="zwx-input" style="width: 200px !important;" placeholder="自动生成" v-model.trim="msFormDepAdd.workplaceCode" maxlength="20" clearable disabled></el-input>
          </el-form-item>
          <el-form-item label-width="110px" label="所在位置：" prop="workplaceAddr">
            <el-input class="zwx-input" style="width: 200px !important;" placeholder="请输入" v-model.trim="msFormDepAdd.workplaceAddr" maxlength="200" clearable></el-input>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="100px" label="负责人：" prop="principal">
            <el-input class="zwx-input" style="width: 200px !important;" placeholder="请输入" v-model.trim="msFormDepAdd.principal" maxlength="50" clearable></el-input>
          </el-form-item>
          <el-form-item label-width="110px" label="固定号码：" prop="fixedTelephone">
            <el-input class="zwx-input" style="width: 200px !important;" placeholder="请输入" v-model.trim="msFormDepAdd.fixedTelephone" maxlength="20" clearable></el-input>
          </el-form-item>
        </div>
        <div class="edit-row">
          <el-form-item label-width="100px" label="手机号：" prop="mobilePhoneNumber">
            <el-input class="zwx-input" style="width: 200px !important;" placeholder="请输入" v-model.trim="msFormDepAdd.mobilePhoneNumber" maxlength="20" clearable></el-input>
          </el-form-item>
        </div>
      </el-form>
    </base-dialog>
    <!-- 添加岗位工种弹框 -->
    <base-dialog class="jobInfo-edit-dialog" ref="addJobDialog" :title="jobDialogTitle" :pagination="false" :if-show-close="true" @cancel="handleCloseJob()" @determine="submitJobAdd" :ifDetermineDis="ifDetermineDis">
      <el-form class="zwx-form edit-form" key="jobSelectForm" :model="msFormAddJob" label-position="right" @submit.native.prevent>
        <div class="edit-row">
          <el-form-item label="岗位（工种）：" label-width="98px">
            <el-input class="zwx-input" style="width: 450px!important;" v-model.trim="msFormAddJob.jobNameLike" placeholder="请输入岗位名称" maxlength="50" clearable></el-input>
          </el-form-item>
          <el-button class="zwx-button zwx-button-32" @click="jobSearch">检索</el-button>
        </div>
      </el-form>
      <el-table class="zwx-table" ref="dataTable" style="width: 100%;" row-key="rid" :data="msFormAddJob.jobInfoAllList" stripe border height="300px" @selection-change="handleSelectionChange" tooltip-effect="light">
        <el-table-column type="selection" width="53" header-align="center" align="center" reserve-selection></el-table-column>
        <el-table-column prop="jobName" label="岗位（工种）名称" width="150" header-align="center" align="left"></el-table-column>
        <el-table-column label="是否接害" width="90" header-align="center" align="center">
          <template slot-scope="scope">
            {{ scope.row.exposureHazards ? '是' : '否' }}
          </template>
        </el-table-column>
        <el-table-column prop="involvedHazards" label="危害因素" width="200" header-align="center" align="left" show-overflow-tooltip :formatter="involvedHazardsFormatter"></el-table-column>
        <el-table-column prop="equippedPpe" label="配备防护用品" width="140" header-align="center" align="left" show-overflow-tooltip :formatter="equippedPpeFormatter"></el-table-column>
        <el-table-column label="操作" min-width="80" header-align="left" align="left">
          <template slot-scope="scope">
            <el-button class="zwx-button zwx-button-text-26" type="text" @click="editJob(scope.row, 2)" icon="el-icon-top-right">
              编辑
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="padding-left:15px;" slot="footer">
        <el-button class="zwx-button zwx-button-icontext-32" icon="el-icon-plus" @click="addNewJob">新增</el-button>
      </div>
    </base-dialog>

    <!-- 部门车间、岗位工种导入弹框 -->
    <data-import ref="deptStationDialogRef" :title="deptStationTitle" accept=".xls,.xlsx,.XLS,.XLSX" :ifshow-upload="$zwxBase.verifyIsBlank(deptStationDialog.annexName)" :size="50 * 1024 * 1024" sizeInfo="50M" :action="api + actionApi" :paramData="deptStationDialog.paramData" :successCount="deptStationDialog.successCount" :errorCount="deptStationDialog.errorCount" :ifShowErrorMsg="$zwxBase.verifyIsNotBlank(deptStationDialog.exportErrorUid)" :determineBtnDisabled="deptStationDialog.determineBtnDisabled" :errorMsg="deptStationDialog.errorMsg" @beforeUpload="fileBeforeUpload" @tempDownload="tempDownload" @deletionFile="deletionFile" @fileSubmitSuccess="fileSubmitSuccess" @fileSubmitError="fileSubmitError" @change="clickFileUpload" @determine="determine" @errorReportDownload="errorReportDownload" @cancel="cancel" @close="close"></data-import>
  </div>
</template>

<script>
export default {
  name: 'DepartmentStation',
  data() {
    return {
      actionApi: '',
      deptStationDialog: {
        annexName: '',
        successCount: 0,
        errorCount: 0,
        exportErrorUid: '',
        determineBtnDisabled: false,
        errorMsg: '',
        paramData: {
          userUid: this.$store.state.users.uid,
          unitId: '',
          uuid: '',
        },
      },
      deptStationTitle: '',
      msForm: {},
      users: this.$store.state.users,
      api: this.$store.state.api,
      currentPage: 1,
      pageSize: 18,
      total: 0,
      addShow: false,
      employerUuid: '',
      workplaceNameLike: '',
      data: [],
      defaultProps: {
        children: 'departmentInfoChildList',
        label: 'workplaceName',
      },
      departId: '', //左侧点击后的部门id
      workplaceName: '', //左侧点击后的部门name
      msFormDepAdd: {
        employerUuid: '',
        rid: '',
        parentWorkplaceId: '',
        workplaceName: '',
        workplaceCode: '',
        workplaceAddr: '',
        principal: '',
        fixedTelephone: '',
        mobilePhoneNumber: '',
      },
      departDialogTitle: '',
      ifModifyDep: '', //部门 判断是新增还是修改
      ifModifyJob: true, //岗位 判断是新增还是修改 默认是新增
      departCascaderData: [], //部门下拉数据源
      jobInfoList: [], //页面右侧 岗中列表
      jobDialogTitle: '',
      ifDetermineDis: true,
      msFormAddJob: {
        rid: '', //部门id
        jobNameLike: '',
        selectedJobs: [], // 已勾选的岗位row
        jobInfoAllList: [], // 列表数据源
      },
      jobIdList: [], //添加后的岗位id集合
      ifAdd: true, //判断新增 提示
      rulesDepAdd: {
        // 上级部门校验
        parentWorkplaceId: [{ validator: this.validateParentWork, trigger: ['change', 'blur'] }],
        workplaceName: [{ required: true, validator: this.validateWorkplaceName, trigger: ['blur', 'change'] }],
        // 手机号码校验
        mobilePhoneNumber: [{ validator: this.validateMobilePhoneNumber, trigger: ['blur'] }],
        // 固定电话校验
        fixedTelephone: [{ validator: this.validateFixedTelephone, trigger: ['blur'] }],
      },
    }
  },
  created() { },
  mounted() {
    let url = this.$route.query
    if (this.$zwxBase.verifyIsNotBlank(url.employerUuid)) {
      this.employerUuid = url.employerUuid
    }
    // 获取左侧 部门车间树形
    this.getDepartmentInfoList()
    // 获取防护用品数据源
    this.getJobPpeList()
  },
  watch: {
    // tree筛选
    searchInput() {
      this.search(this.searchInput)
    },
  },
  activated() {
    // 二级页路由跳转回一级页判断是否需要刷新页面
    if (this.$route.params.fresh == true) {
      this.getDepartmentInfoList()
      // 是从新增岗位弹框 关闭-> 需要打开添加岗位弹框
      // 是从编辑岗位弹框 关闭-> 不需要打开添加岗位弹框
      if (this.ifModifyJob) {
        this.$refs.addJobDialog.show(true)
        this.getJobInfoAllList(2)
      }
    }
  },
  computed: {},
  methods: {
    /**
     * 导入
     */
    handleCommand(command) {
      if (command == 1) {
        this.deptStationTitle = '部门（车间）'
        this.actionApi = '/zky/employer/importDepartmentInfoExcel-0'
        this.$refs.deptStationDialogRef.show(true)
      } else {
        this.deptStationTitle = '岗位（工种）'
        this.actionApi = '/zky/employer/importJobInfoExcel-0'
        this.$refs.deptStationDialogRef.show(true)
      }
    },
    fileBeforeUpload(val) {
      if (!val) this.deptStationDialog.determineBtnDisabled = true
    },
    tempDownload() {
      let apiWay = this.deptStationTitle == '部门（车间）' ? '/zky/employer/downloadDepartmentImportTemplate-0' : '/zky/employer/downloadJobImportTemplate-0'
      this.$system.downloadUrlFile(this.deptStationTitle + '导入模板.xls', this.api + apiWay + '?userUid=' + this.$store.state.users.uid + '&employerUuid=' + this.employerUuid)
    },
    /**
     * 删除文件
     * */
    deletionFile() {
      this.deptStationDialog.annexName = ''
      this.deptStationDialog.exportErrorUid = ''
      this.deptStationDialog.successCount = 0
      this.deptStationDialog.errorCount = 0
      this.deptStationDialog.determineBtnDisabled = false
    },
    fileSubmitSuccess(data) {
      this.$emit('loading', false)
      if (data.type === '00') {
        this.deptStationDialog.successCount = data.successCount || 0
        this.deptStationDialog.errorCount = data.errorCount || 0
        this.deptStationDialog.exportErrorUid = data.exportErrorUid || ''
        if (this.$zwxBase.verifyIsBlank(this.deptStationDialog.exportErrorUid)) {
          this.$refs.deptStationDialogRef.show(false)
          this.$system.notify('成功', '导入成功' + this.deptStationDialog.successCount + '条', 'success')
          // 获取左侧 部门车间树形
          this.getDepartmentInfoList()
        }
      } else {
        this.$system.notify('错误', data.mess, 'error')
        this.deptStationDialog.exportErrorUid = data.exportErrorUid || ''
      }
    },
    fileSubmitError(data) {
      this.$emit('loading', false)
      this.$system.notify('错误', data.mess, 'error')
      this.deptStationDialog.exportErrorUid = data.exportErrorUid || ''
    },
    /**
     * 上传文件
     * */
    clickFileUpload(showList) {
      showList.forEach(item => {
        let flag = this.deptStationDialog.annexName === item
        if (flag) {
          return
        }
        this.deptStationDialog.annexName = item
      })
      if (this.$zwxBase.verifyIsNotBlank(this.deptStationDialog.annexName)) {
        this.deptStationDialog.errorMsg = ''
      }
    },
    /**
     * 导入弹框确认
     * */
    determine() {
      if (this.$zwxBase.verifyIsBlank(this.deptStationDialog.annexName)) {
        this.deptStationDialog.errorMsg = '请上传文件'
        return
      } else {
        this.deptStationDialog.errorMsg = ''
      }
      this.deptStationDialog.determineBtnDisabled = true
      let apiWay = this.deptStationTitle == '部门（车间）' ? '/zky/employer/departmentInfoImportUid-1' : '/zky/employer/jobInfoImportUid-1'
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + apiWay,
        null,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.deptStationDialog.paramData.uuid = data.uid
            this.deptStationDialog.paramData.employerUuid = this.employerUuid
            this.$refs.deptStationDialogRef.fileSubmit()
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    errorReportDownload() {
      let apiWay = this.deptStationTitle == '部门（车间）' ? '/zky/employer/exportDepartmentInfoErrorExcel-0' : '/zky/employer/exportJobInfoErrorExcel-0'
      this.$system.downloadUrlFile(this.deptStationTitle + '错误报告.xls', this.api + apiWay + '?exportErrorUid=' + this.deptStationDialog.exportErrorUid)
    },
    cancel() {
      this.$refs.deptStationDialogRef.show(false)
    },
    close() {
      this.deptStationTitle = ''
      this.deptStationDialog.annexName = ''
      this.deptStationDialog.uid = ''
      this.deptStationDialog.successCount = 0
      this.deptStationDialog.errorCount = 0
      this.deptStationDialog.exportErrorUid = ''
      this.deptStationDialog.paramData.unitId = ''
      this.deptStationDialog.paramData.uuid = ''
      this.deptStationDialog.determineBtnDisabled = false
      this.deptStationDialog.errorMsg = ''
      // 获取左侧 部门车间树形
      this.getDepartmentInfoList()
    },
    /**
     * 固定电话校验
     */
    validateFixedTelephone: function (rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && !/(^\s*$|^0(([1,2]\d)|([3-9]\d{2}))[-]?\d{7,8}$)/.test(value)) {
        return callback(new Error('请输入正确的座机号码'))
      }
      callback()
    },
    /**
     * 手机号码校验
     */
    validateMobilePhoneNumber: function (rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && !/(^\s*$|^[1][3-9]\d{9})/.test(value)) {
        return callback(new Error('请输入正确的手机号码'))
      }
      callback()
    },
    /**
     * 添加/编辑弹框的部门车间校验
     */
    validateWorkplaceName(rule, value, callback) {
      if (this.$zwxBase.verifyIsBlank(value)) return callback(new Error('请输入部门名称'))
      if(value.indexOf('#')!=-1 || value.indexOf('@')!=-1){
        return callback(new Error('不能含有#和@特殊字符'))
      }
      callback()
    },
    /**
     * 上级部门改变回调
     */
    parantDeptIdsChange(value) {
      this.msFormDepAdd.parentWorkplaceId = value
      this.$refs['msFormDepAdd'].clearValidate()
    },
    /**
     * 上级部门校验
     */
    validateParentWork(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(this.msFormDepAdd.rid) && this.$zwxBase.verifyIsNotBlank(value)) {
        if (this.msFormDepAdd.rid === value) {
          return callback(new Error('上级部门不能与当前部门相同'))
        }
      }
      callback()
    },
    /**
     * 节点上浮显示
     */
    mouseenter(data) {
      if (this.$zwxBase.verifyIsBlank(data.rid)) {
        this.$set(data, 'show', false)
      } else {
        this.$set(data, 'show', true)
      }
    },
    mouseleave(data) {
      this.$set(data, 'show', false)
    },
    /**
     * 获取左侧 部门车间树形
     */
    getDepartmentInfoList() {
      let data = {
        // 用人单位uuid 必填
        employerUuid: this.employerUuid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getDepartmentInfoList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.data = data.departmentInfoList
            // 刷新右侧列表
            if (this.$zwxBase.verifyIsBlank(this.departId)) {
              this.getJobInfoList(data.departmentInfoList[0].rid)
              this.departId = data.departmentInfoList[0].rid
              this.workplaceName = data.departmentInfoList[0].workplaceName
            } else {
              this.getJobInfoList(this.departId)
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    // 部门名称搜索
    workplaceNameSelect(workplaceNameLike) {
      let data = {
        // 用人单位uuid 必填
        employerUuid: this.employerUuid,
        workplaceNameLike: workplaceNameLike,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getDepartmentInfoList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.data = data.departmentInfoList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 左侧 部门点击 刷新右侧岗位
     */
    departClick(node) {
      this.getJobInfoList(node.rid)
      this.departId = node.rid
      this.workplaceName = node.workplaceName
      if (this.$zwxBase.verifyIsNotBlank(node.rid)) {
        this.addShow = true
      } else {
        this.addShow = false
      }
    },
    /**
     * 获取部门下拉列表
     */
    getDepartmentDropdownList(rid, departId, type) {
      let data = {
        // 用人单位uuid 必填
        employerUuid: this.employerUuid,
        ifTree: true,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getDepartmentDropdownList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            let departmentInfoList = data.departmentList || []
            if (this.$zwxBase.verifyIsNotBlank(departmentInfoList)) {
              this.dealData(departmentInfoList, rid)
            }
            this.departCascaderData = departmentInfoList
            this.$nextTick(() => {
              if (this.$zwxBase.verifyIsNotBlank(departId)) {
                this.msFormDepAdd.parentWorkplaceId = departId
              }
            })
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    dealData(dataList, rid) {
      for (let i in dataList) {
        if (this.$zwxBase.verifyIsNotBlank(dataList[i].departmentInfoChildList)) {
          if (dataList[i].rid === rid) {
            dataList[i].departmentInfoChildList = null
          } else {
            this.dealData(dataList[i].departmentInfoChildList, rid)
          }
        } else {
          dataList[i].departmentInfoChildList = null
        }
      }
    },
    /**
     * 打开 部门添加弹框
     */
    addDepart(data) {
      this.$refs.addDepartDialog.show(true)
      this.departDialogTitle = '添加部门'
      this.ifModifyDep = false
      this.msFormDepAdd.rid = ''
      this.msFormDepAdd.parentWorkplaceId = ''
      this.msFormDepAdd.workplaceName = ''
      this.msFormDepAdd.workplaceCode = ''
      this.msFormDepAdd.workplaceAddr = ''
      this.msFormDepAdd.principal = ''
      this.msFormDepAdd.fixedTelephone = ''
      this.msFormDepAdd.mobilePhoneNumber = ''
      this.$nextTick(() => {
        this.$refs['msFormDepAdd'].clearValidate()
      })
      // 获取部门下拉
      this.getDepartmentDropdownList(null, data.rid, 'add')
    },
    /**
     * 行内 部门编辑
     */
    editDepartRow(data) {
      this.$refs.addDepartDialog.show(true)
      this.departDialogTitle = '编辑部门'
      this.ifModifyDep = true
      this.$nextTick(() => {
        this.$refs['msFormDepAdd'].clearValidate()
      })
      // 获取部门下拉
      this.getDepartmentDropdownList(data.rid, null, 'edit')
      this.msFormDepAdd.rid = data.rid
      this.msFormDepAdd.parentWorkplaceId = data.parentWorkplaceId
      this.msFormDepAdd.workplaceName = data.workplaceName
      this.msFormDepAdd.workplaceCode = data.workplaceCode
      this.msFormDepAdd.workplaceAddr = data.workplaceAddr
      this.msFormDepAdd.principal = data.principal
      this.msFormDepAdd.fixedTelephone = data.fixedTelephone
      this.msFormDepAdd.mobilePhoneNumber = data.mobilePhoneNumber
    },
    /**
     * 添加部门 点击确定后保存数据
     */
    submitDepAdd() {
      this.$refs.msFormDepAdd.validate(valid => {
        if (valid) {
          let data = {
            // 用人单位uuid 必填
            employerUuid: this.employerUuid,
            rid: this.ifModifyDep ? this.msFormDepAdd.rid : '',
            parentWorkplaceId: this.msFormDepAdd.parentWorkplaceId,
            workplaceName: this.msFormDepAdd.workplaceName,
            workplaceCode: this.msFormDepAdd.workplaceCode,
            workplaceAddr: this.msFormDepAdd.workplaceAddr,
            principal: this.msFormDepAdd.principal,
            fixedTelephone: this.msFormDepAdd.fixedTelephone,
            mobilePhoneNumber: this.msFormDepAdd.mobilePhoneNumber,
          }
          this.$emit('loading', true)
          this.$system.postJson(
            this.api + '/zky/employer/addOrModifyDepartmentInfo-1',
            data,
            true,
            true,
            data => {
              this.$emit('loading', false)
              if (data.type == '00') {
                this.$system.notify('成功', data.mess, 'success')
                // 关闭新增部门弹框
                this.$refs.addDepartDialog.show(false)
                this.getDepartmentInfoList()
              } else if (data.type === '99') {
                this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
              } else {
                this.$system.notify('错误', data.mess, 'error')
              }
            },
            data => {
              this.$emit('loading', false)
              this.$system.notify('错误', data.mess, 'error')
            }
          )
        }
      })
    },
    /**
     * 取消部门弹框
     */
    handleCloseDep() {
      this.$refs.addDepartDialog.show(false)
    },
    /**
     * 关闭部门弹框
     */
    closeDep() {
      this.msFormDepAdd.parentWorkplaceId = ''
      this.msFormDepAdd.workplaceName = ''
      this.msFormDepAdd.workplaceCode = ''
      this.msFormDepAdd.workplaceAddr = ''
      this.msFormDepAdd.principal = ''
      this.msFormDepAdd.fixedTelephone = ''
      this.msFormDepAdd.mobilePhoneNumber = ''
    },
    /**
     * 删除部门
     */
    deleteDepart(row) {
      let msg = '';
      if(row.departmentInfoChildList && row.departmentInfoChildList.length>0){
        msg = '删除后将同步删除下级所有部门信息，是否确定?';
      }else{
        msg = '是否确定删除？';
      }
      this.deleteRidsList = [];
      this.getRids(row);
      this.$system.msgbox('', '提示', msg, '确定', '取消', () => {
        let data = {
          ridList: this.deleteRidsList,
        }
        this.$emit('loading', true)
        this.$system.postJson(
                this.api + '/zky/employer/deleteDepartmentInfo-1',
                data,
                true,
                true,
                data => {
                  if (data.type === '00') {
                    this.$system.notify('成功', data.mess, 'success')
                    this.getDepartmentInfoList()
                  } else if (data.type === '99') {
                    this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
                  } else {
                    this.$system.notify('错误', data.mess, 'error')
                  }
                  this.$emit('loading', false)
                },
                data => {
                  this.$emit('loading', false)
                  this.$system.notify('错误', data.mess, 'error')
                }
        )
      })
    },
    getRids(item){
      this.deleteRidsList.push(item.rid);
      if(this.$zwxBase.verifyIsNotBlank(item.departmentInfoChildList)){
        item.departmentInfoChildList.forEach(item2=>{
          this.getRids(item2)
        })
      }
    },
    /**
     * 右侧 岗位列表
     */
    getJobInfoList(workplaceId) {
      let data = {
        // 用人单位uuid 必填
        employerUuid: this.employerUuid,
        workplaceId: workplaceId,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getJobInfoList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.jobInfoList = data.jobInfoList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 编辑岗位
     */
    editJob(row, type) {
      this.ifAdd = false
      this.$refs.addJobDialog.show(false)
      if (type === 1) {
        this.ifModifyJob = false //确定后 不需要打开添加岗位弹框
      }
      if (type === 2) {
        this.ifModifyJob = true //确定后 打开添加岗位弹框
      }
      this.$router.push({ name: 'JobInfoDetail', params: { rid: row.rid, employerUuid: this.employerUuid, type: 'edit' ,entrance:'depart',} })
    },
    /**
     * 删除岗位
     */
    deleteJob(row) {
      let data = {
        deptId: this.departId, //部门id
        rid: row.rid, //岗位id
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/zky/employer/deleteJobInfo-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.getDepartmentInfoList()
            this.getJobInfoList(this.departId)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 打开添加岗位工种弹框
     */
    addJob() {
      this.$refs.addJobDialog.show(true)
      this.jobDialogTitle = '添加岗位'
      this.getJobInfoAllList(1)
      this.msFormAddJob.selectedJobs = []
      this.msFormAddJob.jobNameLike = ''
      this.jobIdList = [] // 清空已选岗位id
      this.ifDetermineDis = true
    },
    /**
     * 获取单位下所有岗位 过滤已有岗位
     */
    getJobInfoAllList(type) {
      var notJobIdList = []
      this.jobInfoList.forEach(item => notJobIdList.push(item.rid))
      var notJobIdText = this.$zwxBase.verifyIsNotBlank(notJobIdList) ? notJobIdList.join(',') : ''
      let data = {
        // 用人单位uuid 必填
        employerUuid: this.employerUuid,
        notWorkplaceId: this.departId, //非此部门id
        notJobIdText: notJobIdText, //已有岗位拼接
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getJobInfoList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msFormAddJob.jobInfoAllList = data.jobInfoList
            this.msFormAddJob.jobNameLike = ''
            // 判断已勾选的集合是否有值 将原先勾选内容 和新增岗位一并勾选
            if (type === 2) {
              if (this.$zwxBase.verifyIsNotBlank(this.jobIdList)) {
                let rowList = this.msFormAddJob.jobInfoAllList.filter(item => {
                  if (this.jobIdList.indexOf(item.rid) > -1) return item
                })
                if (this.$zwxBase.verifyIsNotBlank(rowList)) {
                  rowList.forEach(row => {
                    this.$refs.dataTable.toggleRowSelection(row, true)
                  })
                }
              }
            }
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    /**
     * 提交添加岗位工种弹框
     */
    submitJobAdd() {
      if (this.$zwxBase.verifyIsBlank(this.departId)) {
        this.$refs.addJobDialog.show(false)
        return
      }
      var jobInfoList = []
      if (this.$zwxBase.verifyIsNotBlank(this.msFormAddJob.selectedJobs)) {
        this.msFormAddJob.selectedJobs.forEach(item => {
          jobInfoList.push({ rid: item.rid })
        })
      }
      let data = {
        employerUuid: this.employerUuid,
        rid: this.departId, //部门id
        jobInfoList: jobInfoList,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/zky/employer/saveDepartmentWorkPosition-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$refs.addJobDialog.show(false)
            // this.getDepartmentInfoList()
            this.getJobInfoList(this.departId)
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },
    /**
     * 关闭添加岗位工种弹框
     */
    handleCloseJob() {
      this.$refs.addJobDialog.show(false)
      this.msFormAddJob.rid = ''
      this.msFormAddJob.jobNameLike = ''
      this.msFormAddJob.selectedJobs = []
      this.jobIdList = []
    },
    /**
     * 岗位工种 搜索
     */
    jobSearch() {
      var notJobIdList = []
      this.jobInfoList.forEach(item => notJobIdList.push(item.rid))
      var notJobIdText = this.$zwxBase.verifyIsNotBlank(notJobIdList) ? notJobIdList.join(',') : ''
      let data = {
        // 用人单位id 必填
        employerUuid: this.employerUuid,
        jobNameLike: this.msFormAddJob.jobNameLike,
        notJobIdText: notJobIdText,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/zky/employer/getJobInfoList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type == '00') {
            this.msFormAddJob.jobInfoAllList = data.jobInfoList
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        data => {
          this.$emit('loading', false)
          this.$system.notify('错误', data.mess, 'error')
        }
      )
    },

    /**
     * 岗位工种 多选
     */
    handleSelectionChange(val) {
      if (this.$zwxBase.verifyIsNotBlank(val)) {
        this.msFormAddJob.selectedJobs = val
        this.ifDetermineDis = false
        val.forEach(item => {
          this.jobIdList.push(item.rid)
        })
      } else {
        this.msFormAddJob.selectedJobs = []
        this.jobIdList = []
        this.ifDetermineDis = true
      }
    },
    /**
     * 新增岗位 打开弹框
     */
    addNewJob() {
      this.$router.push({ name: 'JobInfoDetail', params: { employerUuid: this.employerUuid, type: 'add' ,entrance:'depart'} })
      this.$refs.addJobDialog.show(false)
    },
    /**
     * 防护用品数据源
     */
    getJobPpeList() {
      var list = []
      this.$SimpleCodeTools.getSimpleCodeListByCodeTypeCodes('3061', data => {
        list = data['3061']
        if (this.$zwxBase.verifyIsNotBlank(list)) {
          this.simpleCodeList = this.manyListFul(list, '')
        }
      })
    },
    manyListFul(data, pid) {
      //递归方法 生成 json tree 数据
      var result = [],
        temp,
        obj
      data.some(e => {
        let LevelNoArr = e.codeLevelNo.split('.')
        if ((e.codeLevelNo == e.codeNo && pid == '') || LevelNoArr[LevelNoArr.length - 2] == pid) {
          obj = { ...e }
          temp = this.manyListFul(data, e.codeNo)
          if (temp.length > 0) {
            obj.children = temp
          }
          result.push(obj)
        }
      })
      return result
    },
    /**
     * 危害因素格式化
     */
    involvedHazardsFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return cellValue
      } else {
        return ''
      }
    },
    /**
     * 防护用品格式化
     */
    equippedPpeFormatter(row, column, cellValue, index) {
      if (this.$zwxBase.verifyIsNotBlank(cellValue)) {
        return cellValue
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-icon-edit::before {
  font-size: 16px;
}

//添加岗位弹框   隐藏滚动条上方的一个方块
/deep/.el-table th.gutter {
  background: transparent !important;
  border: none !important;
}

/deep/.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #fff !important; //背景色
  color: blue !important;
}
// 弹框的  tree   checkbox选择框颜色
/deep/.el-checkbox__inner {
  border: 1px solid #316cf5 !important;
}

.import-dept {
  position: fixed;
  left: 105px;
  top: 9px;
}
.department-station {
  display: flex;
  flex-direction: row;
  flex: 1;
  flex-basis: auto;
  box-sizing: border-box;

  .station-list {
    width: 450px;
    height: calc(100vh - 44px);
    border-right: 1px solid #e6e6e6;

    .station-list-title {
      height: 40px;
      padding-left: 20px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 16px;
      text-align: left;
      color: #000000;
      line-height: 40px;
    }

    .station-search {
      padding: 12px 0px 12px 12px;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .equipment-detail {
    width: calc(100% - 450px);

    .header-title {
      padding: 6px 20px;
      font-size: 16px;

      .zwx-button {
        margin-left: 24px;
      }
    }
  }
}

.recall-dialog /deep/ .el-dialog {
  width: 350px !important;
  min-width: 350px !important;
}
/deep/ .el-tree {
  color: #000000;
  line-height: 38px !important;
  // 将每一行的设置为相对定位 方便后面before after 使用绝对定位来固定位置
  // overflow: auto; //注释这个  可以清除弹框的 树 的 纵向滚动条
  .el-tree-node {
    position: relative;
    padding-left: 0px;
  }
  // 子集像右偏移 给数线留出距离
  .el-tree-node__children {
    padding-left: 42px;
    color: #606266;
    line-height: 30px !important;
  }
  //这是竖线
  .el-tree-node :last-child:before {
    height: 40px;
  }
  .el-tree > .el-tree-node:before {
    border-left: none;
  }
  .el-tree > .el-tree-node:after {
    border-top: none;
  }
  //这自定义的线 的公共部分
  .el-tree-node:before,
  .el-tree-node:after {
    content: '';
    left: -4px;
    position: absolute;
    right: auto;
    border-width: 1px;
  }
  .tree :first-child .el-tree-node:before {
    border-left: none;
  }
  // 竖线
  .el-tree-node:before {
    border-left: 1px solid #b3b7cc;
    bottom: 0px;
    height: 100%;
    top: -25px;
    width: 1px;
  }
  //横线
  .el-tree-node:after {
    border-top: 1px solid #b3b7cc;
    height: 20px;
    top: 14px;
    width: 6px;
  }
  .el-tree-node__expand-icon.is-leaf {
    width: 10px;
  }
  //每一行的高度
  .el-tree-node__content {
    // line-height: 30px;
    height: 30px;
    padding-left: 5px !important;
  }
  .el-tree-node__content:hover {
    background-color: unset !important;
    color: blue;
  }
  .el-tree-node:focus > .el-tree-node__content {
    background-color: #fff !important; //背景色
    color: blue !important;
  }

  .table-item-add-icon {
    float: right;
    justify-content: flex-end;
  }
}
//去掉最上级的before  after 即是去电最上层的连接线
/deep/ .el-tree > div {
  &::before {
    display: none;
  }
  &::after {
    display: none;
  }
}
.depart-edit-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      width: 772px !important;
      min-width: 772px !important;
    }
  }
}
.jobInfo-edit-dialog {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      width: 724px !important;
      min-width: 724px !important;
    }
  }
}
/deep/ .zwx-select .el-input__inner {
  width: 200px !important;
}
.depart-people {
  margin-left: 14px;
  color: #a8bbcf;
}
.depart-add {
  width: 18px;
  height: 18px;
}
.depart-edit {
  margin-left: 14px;
}
.depart-delete {
  width: 18px;
  height: 18px;
  margin-left: 14px;
}
.name-hidden {
  white-space: nowrap;
  width: 80px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.hazard-name-hidden {
  white-space: nowrap;
  width: 150px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: left;
  font-size: 14px;
}
.hazard-cas-hidden {
  white-space: nowrap;
  width: 60px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: right;
}
.principal-hidden {
  white-space: nowrap;
  width: 20x;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
/deep/ .zwx-cascader-single-depart .zwx-input {
  width: 200px !important;
}
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  // 设置颜色
  background-color: rgba(135, 206, 235, 0.2); // 透明度为0.2的skyblue，作者比较喜欢的颜色
  color: #409eff; // 节点的字体颜色
  font-weight: bold; // 字体加粗
}
</style>
<style lang="less">
.el-tooltip__popper {
  background: white !important; /*背景色  !important优先级*/
  color: #0f0101 !important; /*字体颜色*/
  opacity: 1 !important; /*背景色透明度*/
  max-width: 600px;
}
</style>
